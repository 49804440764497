import Calendar from '@/models/company/Calendar'
import { orderBy } from 'lodash-es'

export default class CalendarCollection {
  constructor(calendars = []) {
    this.calendars = calendars.map(calendar => new Calendar(calendar))
  }

  all() {
    return this.calendars
  }

  length() {
    return this.calendars.length
  }

  latestCalendar() {
    return orderBy(this.calendars, ['start_date'], ['desc'])[0]
  }

  earliestCalendar() {
    return orderBy(this.calendars, ['start_date'], ['asc'])[0]
  }

  visibleCalendars() {
    return this.calendars.filter(calendar => !calendar.hidden)
  }

  findCalendarForYear(date) {
    return this.calendars.find(c => c.start_date.isSame(date, 'year'))
  }

  first() {
    return this.calendars[0]
  }
}
