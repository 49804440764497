<template>
  <div>
    <button
      ref="target"
      type="button"
      class="tw-flex tw-items-center"
      data-vtu="extra-info-btn"
    >
      <slot name="button">
        <svg-icon
          :name="icon"
          style="width: 15px; height: 15px;"
          class="tw-mx-2 icon-trash"
          :class="iconColour"
        />
      </slot>
    </button>

    <div v-show="false" ref="content">
      <slot />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'ExtraInfo',

  props: {
    icon: {
      type: String,
      default: () => 'exclamation-solid',
    },
    iconColour: {
      type: String,
      default: () => 'tw-text-gray-440',
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.target, {
      html: this.$refs.content.firstChild,
      placement: 'right',
      distance: 1,
      interactiveBorder: 15,
      interactive: true,
      theme: 'dark',
      arrow: true,
      delay: [400, 0],
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },
}
</script>
