import { filter, sortBy } from 'lodash-es'
import EmploymentProfile from '@/api/profile/EmploymentProfile'
import CompanyProfile from '@/api/profile/CompanyProfile'
import UserProfile from '@/api/profile/UserProfile'

const initialState = {
  user: {
    id: null,
    verified_at: null,
    companies: [],
  },
  company: null,
  employment: null,
  inActiveEmploymentTimezone: false,
}

export const state = {
  ...initialState,
  initialState() {
    return initialState
  },
}

export const getters = {
  check({ user }) {
    return user.id !== null
  },

  verified({ user }) {
    return user.id && !!user.verified_at
  },

  activeCompany(state) {
    return state.company ? state.company : null
  },

  activeEmployment(state) {
    return state.employment ? state.employment : null
  },

  companies({ user }) {
    return sortBy(user.companies, ['name'])
  },

  employments({ user }) {
    return user.employments
  },
}

export const mutations = {
  setAuthUser(state, user) {
    state.user = {
      ...user,
      companies: user.companies.map(company => ({
        id: company.id,
        name: company.name,
      })),
    }
  },

  setActiveCompany(state, company) {
    state.company = company
  },

  useActiveEmploymentTimezone(state, status) {
    state.inActiveEmploymentTimezone = status
  },

  markCalendarErrorAsDismissed(
    state,
    { activeEmployment, dismissingCalendar }
  ) {
    const otherEmployments = filter(
      state.user.employments,
      employment => employment.id !== activeEmployment.id
    )

    const otherFailedCalendarServices = filter(
      activeEmployment.failed_calendar_services,
      calendar => calendar.id !== dismissingCalendar.id
    )

    state.user = {
      ...state.user,
      employments: [
        ...otherEmployments,
        {
          ...activeEmployment,
          failed_calendar_services: [
            ...otherFailedCalendarServices,
            {
              ...dismissingCalendar,
              isDismissed: true,
            },
          ],
        },
      ],
    }
  },

  setActiveEmployment(state, employment) {
    state.employment = employment
  },
}

export const actions = {
  async fetchUser(
    { commit, state, getters, dispatch },
    isCheckAuthentication = false
  ) {
    const { data: user } = await UserProfile.show()

    commit('setAuthUser', user)

    if (isCheckAuthentication) {
      let company = null
      if (state.user.settings.default_company) {
        company = {
          id: state.user.settings.default_company,
        }
      } else {
        company = getters.companies[0]
      }

      commit('setActiveCompany', company)

      await dispatch('fetchCompany', company)
    }
  },

  async fetchCompany({ commit, dispatch, rootState }, company) {
    await dispatch('billing/fetchBillingPlans', null, { root: true })

    let fetchedCompany = await CompanyProfile.show(
      company,
      rootState.billing.plans
    )

    commit('setActiveCompany', fetchedCompany)

    await dispatch('fetchEmployment', fetchedCompany)
  },

  async fetchEmployment({ commit }, company) {
    const employment = await EmploymentProfile.show(company)

    commit('setActiveEmployment', employment)
  },
}
