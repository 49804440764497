import { map } from 'lodash-es'
import http from '@/plugins/http'
import Leave from '@/models/leave/Leave'
import Timeline from '@/components/requests/leave/events/Timeline'
import SingleEmploymentLeaveRequest from '@/models/leave/drafting/SingleEmploymentLeaveRequest'
import MultipleEmploymentLeaveRequest from '@/models/leave/drafting/MultipleEmploymentLeaveRequest'

export default class Leaves {
  static all(params) {
    return http.get('leaves', {
      params: params,
    })
  }

  static request(payload) {
    return http.post('leaves', {
      ...payload,
      from: payload.from.format('YYYY-MM-DD HH:mm:ss'),
      to: payload.to.format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  static async draft(
    params,
    owners,
    employmentAllowances,
    leaveBreakdowns = null
  ) {
    const { company, leaveType, from, to } = params

    const draftLeavePayload = {
      company_id: company.id,
      type_id: leaveType.id,
      from: from.format('YYYY-MM-DD HH:mm:ss'),
      to: to.format('YYYY-MM-DD HH:mm:ss'),
    }

    if (leaveBreakdowns) {
      draftLeavePayload.leave_breakdowns = leaveBreakdowns
    } else {
      draftLeavePayload.owner_id = map(owners, 'id')
    }

    const { data } = await http.post('leaves/draft', draftLeavePayload)

    data.employments = data.employments.map(employment => {
      const allowance = employmentAllowances.whereOwner(employment.owner_id)

      return {
        ...owners.find(owner => owner.id === employment.owner_id),
        ...employment,
        company: company,
        minutes_per_working_day: allowance?.minutesPerWorkingDay,
        allowance_unit_is_days: allowance?.allowanceUnitIsDays,
      }
    })

    if (owners.length === 1) {
      return new SingleEmploymentLeaveRequest(data)
    }

    return new MultipleEmploymentLeaveRequest(data)
  }

  static update(leaveKey, payload) {
    return http.put(`leaves/${leaveKey}`, {
      ...payload,
      from: payload.from.format('YYYY-MM-DD HH:mm:ss'),
      to: payload.to.format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  static async get(leaveKey, company) {
    const { data } = await http.get(`leaves/${leaveKey}`, {
      params: {
        company_id: company.id,
      },
    })

    return new Leave(data, company)
  }

  static async getRequestedLeave(company) {
    const { data } = await http.get('requested-leaves', {
      params: { company: company.id },
    })

    return data.map(leave => new Leave(leave, company))
  }

  static async getTimeline(leaveKey, params) {
    const { data } = await http.get(`leaves/${leaveKey}/timeline`, {
      params: params,
    })

    return new Timeline(data)
  }

  static approve(leaveKey, payload) {
    return http.patch(`leaves/${leaveKey}/approve`, payload)
  }

  static cancel(leaveKey, payload) {
    return http.patch(`leaves/${leaveKey}/cancel`, payload)
  }

  static retract(leaveKey, payload) {
    return http.patch(`leaves/${leaveKey}/retract`, payload)
  }

  static addComment(leaveKey, payload) {
    return http.post(`leave/${leaveKey}/comments`, payload)
  }

  static async redraft(leave, payload) {
    payload = {
      ...payload,
      from: payload.from.format('YYYY-MM-DD HH:mm:ss'),
      to: payload.to.format('YYYY-MM-DD HH:mm:ss'),
    }

    const { data } = await http.post(`leaves/${leave.id}/redraft`, payload)

    data.employments[0] = {
      ...data.employments[0],
      ...leave.owner,
    }

    return new SingleEmploymentLeaveRequest(data)
  }

  static saveAttachment(key, payload) {
    return http.post(`leaves/${key}/attachments`, payload)
  }
}
