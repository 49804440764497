import http from '@/plugins/http'
import Company from '@/models/company/Company'

export default class CompanyProfile {
  static async show(company, billingPlans) {
    const { data: response } = await http.get(`me/companies/${company.id}`)

    response.billing_plan = billingPlans.findPlanByName(
      response.current_billing_plan
    )

    return new Company(response)
  }
}
