import { round } from 'lodash-es'
import moment from 'moment-timezone'
import AllowanceType from '@/models/company/AllowanceType'

export default class EmploymentAllowance {
  constructor(allowance) {
    this.allowance = {
      ...allowance,
      allowance_type: new AllowanceType(allowance.allowance_type),
    }
  }

  employment() {
    return this.allowance.employment
  }

  allowanceType() {
    return this.allowance.allowance_type
  }

  calendar() {
    return this.allowance.calendar
  }

  isUnlimited() {
    return this.allowance.is_unlimited
  }

  allowanceUnit() {
    return this.allowance.allowance_unit
  }

  totalAllowance() {
    return round(this.allowance.total_allowance, 2)
  }

  annualAllowance() {
    return round(this.allowance.annual_allowance, 2)
  }

  takenAllowance() {
    return round(this.allowance.taken_allowance, 2)
  }

  bookedAllowance() {
    return round(this.allowance.booked_allowance, 2)
  }

  remainingToBook() {
    return round(this.allowance.remaining_to_book, 2)
  }

  remainingToTake() {
    return round(this.allowance.remaining_to_take, 2)
  }

  totalTimeOffInLieu() {
    return round(this.allowance.time_off_in_lieu, 2)
  }

  carryoverAllowance() {
    return round(this.allowance.carry_over_allowance, 2)
  }

  expiredCarryoverAllowance() {
    if (this.allowance.expired_carry_over_allowance === null) {
      return null
    }

    return round(this.allowance.expired_carry_over_allowance, 2)
  }

  carriedAllowanceToNextYear() {
    return round(this.allowance.carried_allowance_to_next_year, 2)
  }

  isCarryoverLocked() {
    return !!this.allowance.is_carry_over_locked
  }

  accruedAllowance() {
    return this.allowance.accrued_allowance
  }

  hasTimeOffInLieu() {
    return this.allowance.has_time_off_in_lieu
  }

  totalBookedAndTakenAllowances() {
    return round(this.takenAllowance() + this.bookedAllowance(), 2)
  }

  usedCarryoverAllowance() {
    return round(
      this.carryoverAllowance() - this.expiredCarryoverAllowance() ?? 0,
      2
    )
  }

  takenCarryoverAllowance() {
    return round(this.allowance.taken_carry_over_allowance, 2)
  }

  carryoverExpiryDate() {
    const date = this.allowance.carry_over_expiry_date

    return date ? moment.utc(date) : null
  }

  remainingCarryover() {
    return round(this.carryoverAllowance() - this.takenCarryoverAllowance(), 2)
  }

  hasExpiredCarryover() {
    if (!this.carryoverExpiryDate()) {
      return false
    }

    return this.carryoverExpiryDate()
      .clone()
      .endOf('day')
      .isBefore(
        moment()
          .utc(true)
          .endOf('day')
      )
  }

  accruedRemainingToBook() {
    return this.allowance.accrued_remaining_to_book
  }

  accruedRemainingToTake() {
    return this.allowance.accrued_remaining_to_take
  }

  isOwner(ownerKey) {
    return this.employment().id === ownerKey
  }

  get allowanceUnitIsDays() {
    return this.allowance.allowance_unit === 'Days'
  }

  get minutesPerWorkingDay() {
    return this.employment().minutes_per_working_day
  }
}
