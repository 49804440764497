<template>
  <div>
    <div ref="target" class="tw-inline-block" :class="targetClasses">
      <slot></slot>
    </div>

    <div v-if="isActive && feature" v-show="false" ref="tooltip">
      <div class="tw-flex tw-mt-5">
        <div
          class="tw-rounded-sm tw-p-3 tw-border tw-border-gray-300 tw-bg-gray-200"
        >
          <div class="tw-flex">
            <span class="">Upgrade to</span>
            <LowestCostPlanLabel class="tw-px-1 tw-mx-1" :feature="feature" />
          </div>
          <div>plan to {{ actionMessage }}.</div>
          <RouterLink
            v-if="activeEmployment.isAdmin()"
            :to="{ name: 'billing' }"
            class="btn-link tw-items-end tw-mt-3 tw-inline-block tw-float-right"
          >
            Go to billing
            <SvgIcon name="arrow-right" class="tw-w-5 tw-h-5 tw-ml-2" />
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LowestCostPlanLabel from '@/components/LowestCostPlanLabel'
import tippy from 'tippy.js'

export default {
  name: 'UpgradePlanPopup',
  components: { LowestCostPlanLabel },
  props: {
    feature: {
      type: String,
      required: true,
    },

    actionMessage: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    targetClasses: {
      type: String,
      default: '',
    },
  },

  mounted() {
    if (this.isActive) {
      this.tippy = tippy.one(this.$refs.target, {
        html: this.$refs.tooltip.firstChild,
        interactive: true,
        placement: 'bottom-start',
        distance: 4,
        offset: '-13,0',
        theme: 'light',
        animation: 'scale',
        inertia: true,
        arrow: true,
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    }
  },
}
</script>
