import moment from 'moment/moment'
import DayOverlap from '@/models/reporting/DayOverlap'

export default class OverlapCollection {
  constructor(overlaps = []) {
    this.overlaps = overlaps.map(overlap => new DayOverlap(overlap))
  }

  hasLeaveLimitExceeded() {
    return this.getLeaveLimitExceededOverlaps().length > 0
  }

  getOverlapScope() {
    let overlapScopes = []

    this.overlaps.forEach(function(overlap) {
      if (!overlapScopes.includes(overlap.leaveLimitScope)) {
        overlapScopes.push(overlap.leaveLimitScope)
      }
    })

    if (overlapScopes.includes('company')) {
      return 'company'
    }

    return 'department'
  }

  whereDate(date) {
    return this.overlaps.find(overlap => overlap.date.isSame(date, 'day'))
  }

  filterByDate(date) {
    return this.overlaps.filter(overlap => {
      return moment(overlap.date).isSame(moment(date), 'date')
    })
  }

  getLeaveLimitExceededOverlaps() {
    return this.overlaps.filter(overlap => overlap.isLeaveLimitExceeded())
  }

  getBeforeHandOverOverlaps(leave) {
    return this.overlaps.filter(overlap => {
      const overlapDate = overlap.date.clone().tz(leave.timezone, true)

      const leaveStart = leave.start.clone().startOf('day')

      return overlapDate.isBefore(leaveStart)
    })
  }

  getAfterHandOverOverlaps(leave) {
    return this.overlaps.filter(overlap => {
      const overlapDate = overlap.date.clone().tz(leave.timezone, true)

      const leaveEnd = leave.end.clone().endOf('day')

      return overlapDate.isAfter(leaveEnd)
    })
  }
}
