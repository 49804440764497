var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-1 tw-flex-col"},[(!_vm.isLeaveEditing)?_c('div',{staticClass:"tw-mb-4 tw-pb-4 tw-space-x-6 tw-border-b tw-border-gray-220 tw-flex tw-items-center tw-justify-end"},[_c('LowestCostPlanLabel',{staticClass:"tw-mr-2 tw-px-2 tw-p-1",attrs:{"feature":_vm.Feature.Handover}}),_c('div',{staticClass:"tw-space-x-2 tw-items-center"},[_c('UpgradePlanPopup',{attrs:{"action-message":"show handover","feature":_vm.Feature.Handover,"is-active":!_vm.companyHasHandoverFeature}},[_c('ToggleButton',{attrs:{"labels":true,"color":"#1da1f2","disabled":!_vm.companyHasHandoverFeature},on:{"change":_vm.onToggleDebounced},model:{value:(_vm.showHandover),callback:function ($$v) {_vm.showHandover=$$v},expression:"showHandover"}}),_c('span',{staticClass:"tw-text-gray-770 tw-font-medium tw-text-base"},[_vm._v(" Show Handover ")])],1)],1),_c('div',[_c('LeaveDetailsFilter',{attrs:{"container-class":".slideout","selected-filters":{
          overlapScope: _vm.overlapScope,
          dayScope: _vm.dayScope,
          conflicts: _vm.conflicts,
        }},on:{"change-filters":_vm.filterChanged}})],1)],1):_vm._e(),(!_vm.isLeaveEditing)?_c('ScrollableContent',[_c('Loading',{attrs:{"loader":"dots","is-full-page":false,"active":_vm.showHandover && _vm.loading}}),(_vm.showHandover)?_c('div',{staticClass:"tw-mb-3 tw-text-lg tw-font-medium"},[_vm._v(" Before "),_c('Loading',{staticClass:"tw-relative tw-h-1",attrs:{"loader":"dots","is-full-page":false,"active":_vm.showHandover && _vm.beforeHandoverOverlaps.length === 0}})],1):_vm._e(),_c('div',_vm._l((_vm.beforeHandoverOverlaps),function(overlap,index){return _c('HandoverBreakdown',{key:index,attrs:{"day-overlap":overlap},on:{"show-overlap-leave-details":leaveKey => _vm.$emit('show-overlap-leave-details', leaveKey)}})}),1),_c('div',{class:{
        'tw-my-5 tw-p-4 tw-border tw-rounded-lg tw-bg-blue-200 tw-border-blue-350 tw-flex tw-flex-col': _vm.showHandover,
      }},[(_vm.showHandover)?_c('div',{staticClass:"tw-mb-3 tw-text-lg tw-font-medium"},[_vm._v(" Requests ")]):_vm._e(),_c('Loading',{attrs:{"loader":"dots","is-full-page":false,"active":_vm.loading}}),(!_vm.loading)?_c('div',[_c('div',{staticClass:"tw-space-y-2"},_vm._l((_vm.leaveBreakdowns),function(breakdown,index){return _c('LeaveBreakdown',{key:index,attrs:{"breakdown":breakdown,"day-overlap":_vm.findDayOverlap(breakdown),"timezone":_vm.leave.timezone},on:{"show-overlap-leave-details":leaveKey => _vm.$emit('show-overlap-leave-details', leaveKey)}})}),1),_c('div',{staticClass:"tw-my-4 tw-space-y-3 tw-tw-flex tw-justify-end"},[_c('div',{staticClass:"tw-text-right tw-text-lg tw-font-semibold tw-text-gray-880",attrs:{"data-cy":"leave-duration"}},[_vm._v(" "+_vm._s(_vm.leaveDuration)+" ")]),_c('div',{staticClass:"tw-text-right"},[_c('LeaveTypeLabel',{attrs:{"leave":_vm.leave}})],1)])]):_vm._e()],1),(_vm.showHandover)?_c('div',{staticClass:"tw-mb-3 tw-text-lg tw-font-medium"},[_vm._v(" After "),_c('Loading',{staticClass:"tw-relative tw-h-1",attrs:{"loader":"dots","is-full-page":false,"active":_vm.showHandover && _vm.afterHandoverOverlaps.length === 0}})],1):_vm._e(),_c('div',_vm._l((_vm.afterHandoverOverlaps),function(overlap,index){return _c('HandoverBreakdown',{key:index,attrs:{"day-overlap":overlap},on:{"show-overlap-leave-details":leaveKey => _vm.$emit('show-overlap-leave-details', leaveKey)}})}),1)],1):_vm._e(),(_vm.isLeaveEditing)?_c('div',{staticClass:"tw-flex tw-flex-1"},[_c('EditLeaveDetails',{staticClass:"tw-flex tw-flex-1",attrs:{"leave":_vm.leave},on:{"quit-edit-mode":function($event){return _vm.$emit('quit-leave-edit-mode')},"has-leave-edit-errors":value => _vm.$emit('has-leave-edit-errors', value),"show-overlap-leave-details":leaveKey => _vm.$emit('show-overlap-leave-details', leaveKey),"leave-updated":function($event){return _vm.$emit('leave-updated')}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }