import BillingPlan from '@/models/Billing/BillingPlan'

export default class BillingPlanCollection {
  constructor(data = null) {
    this.plans =
      data === null
        ? []
        : data.plans.map(plan => {
            const planWithFeatures = data.plan_features.find(
              planWithFeatures => planWithFeatures.plan === plan.plan
            )

            return new BillingPlan(plan, planWithFeatures.features)
          })
  }

  findSmallestPlanHasFeature(feature) {
    let planNamesInOrder = ['starter', 'essential', 'plus']

    for (const planName of planNamesInOrder) {
      const plan = this.findPlan(planName)

      if (plan?.hasFeature(feature)) {
        return plan
      }
    }

    return null
  }

  findPlan(plan) {
    return this.plans.find(p => p.isPlan(plan))
  }

  findPlanByName(name) {
    return this.plans.find(p => p.isSame(name))
  }

  isEmpty() {
    return this.plans.length === 0
  }
}
