export default class BillingPlan {
  constructor(data, features) {
    this.name = data.plan
    this.displayName = data.display_name
    this.features = features
  }

  isPlan(name) {
    return this.name === name
  }

  isSame(displayName) {
    return (
      this.displayName.trim().toLowerCase() === displayName.trim().toLowerCase()
    )
  }

  hasFeature(feature) {
    return this.features.includes(feature)
  }
}
