import moment from 'moment-timezone'

export default class ICalFeed {
  constructor(employee = {}) {
    this.employee = employee
  }

  hasEmployeeICalFeedIssue() {
    return this.hasIssue(this.employeeICalFeed())
  }

  hasDepartmentICalIssue() {
    return this.hasIssue(this.departmentICalFeed())
  }

  hasCompanyICalIssue() {
    return this.hasIssue(this.companyICalFeed())
  }

  hasIssue(iCalFeed) {
    return iCalFeed ? this.calculateTimeDifferenceFromNow(iCalFeed) > 24 : false
  }

  employmentICalFeedLastReadAt() {
    return this.iCalFeedLastReadAt(this.employeeICalFeed())
  }

  departmentICalFeedLastReadAt() {
    return this.iCalFeedLastReadAt(this.departmentICalFeed())
  }

  companyICalFeedLastReadAt() {
    return this.iCalFeedLastReadAt(this.companyICalFeed())
  }

  employeeICalFeed() {
    return this.employee.ical_feed_last_read_at?.employment
  }

  departmentICalFeed() {
    return this.employee.ical_feed_last_read_at?.department
  }

  companyICalFeed() {
    return this.employee.ical_feed_last_read_at?.company
  }

  iCalFeedLastReadAt(iCalFeed) {
    if (iCalFeed) {
      let diffInMinutes = this.calculateTimeDifferenceFromNow(
        iCalFeed,
        'minutes'
      )

      let timeAgo

      if (diffInMinutes < 60) {
        timeAgo = `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`
      } else if (diffInMinutes < 1440) {
        const hours = Math.floor(diffInMinutes / 60)
        timeAgo = `${hours} hour${hours === 1 ? '' : 's'} ago`
      } else {
        const days = Math.floor(diffInMinutes / 1440)
        timeAgo = `${days} day${days === 1 ? '' : 's'} ago`
      }

      return `Last requested ${timeAgo} (${this.formatDate(iCalFeed)})`
    }

    return null
  }

  formatDate(date) {
    let dateFormat =
      this.employee.user.locale === 'en_GB' ? 'DD-MM-YYYY' : 'MM-DD-YYYY'

    let timeFormat = this.employee.user.settings.in_24_hour_clock
      ? 'HH:mm'
      : 'h:mma'

    return moment
      .utc(date)
      .tz(this.employee.timezone)
      .format(`${dateFormat} ${timeFormat}`)
  }

  calculateTimeDifferenceFromNow(date, granularity = 'hours') {
    date = moment.utc(date)

    return moment.utc().diff(date, granularity)
  }
}
