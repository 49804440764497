import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'

export default class EmploymentAllowanceCollection {
  constructor(allowances) {
    this.allowances = allowances.map(
      allowance => new EmploymentAllowance(allowance)
    )
  }

  length() {
    return this.allowances.length
  }

  all() {
    return this.allowances
  }

  first() {
    return this.allowances[0] ?? null
  }

  whereHasAllowances() {
    return this.whereHasLimitedAllowances().filter(
      allowance => allowance.totalAllowance() !== 0
    )
  }

  whereHasLimitedAllowances() {
    return this.allowances.filter(allowance => !allowance.isUnlimited())
  }

  whereHasToilOffered() {
    return this.allowances.filter(allowance => allowance.hasTimeOffInLieu())
  }

  whereOwner(ownerKey) {
    return this.allowances.find(allowance => allowance.isOwner(ownerKey))
  }
}
