import Vue from 'vue'
import http from './http'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

const echo = new Echo({
  broadcaster: 'reverb',
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
  authorizer: channel => {
    return {
      authorize: (socketId, callback) => {
        http
          .post('broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then(response => {
            callback(false, response.data)
          })
          .catch(error => {
            callback(true, error)
          })
      },
    }
  },
})

Vue.prototype.$echo = echo

export default echo
