import http from '@/plugins/http'
import BillingPlanCollection from '@/models/Billing/BillingPlanCollection'

export default class Billing {
  static async getBillingDetails(params) {
    return http.get(`billing/details`, {
      params: params,
    })
  }

  static getRenewSubscription(params) {
    return http.get(`billing/renew-subscription`, {
      params: params,
    })
  }

  static getBillingPortal(params) {
    return http.get(`billing/portal`, {
      params: params,
    })
  }

  static async fetchBillingPlans() {
    const { data } = await http.get('billing/plans')

    return new BillingPlanCollection(data)
  }

  static async connectSubscriptionToCompany(subscription, company) {
    await http.get(`/connect-subscription/${subscription}/${company}`)
  }
}
