import { http } from '@/plugins'

export default class Limits {
  static get(companyKey) {
    return http.get('leave-limits', { params: { company: companyKey } })
  }

  static create(params) {
    return http.post('leave-limits', params)
  }

  static update(limitKey, params) {
    return http.put(`leave-limits/${limitKey}`, params)
  }

  static delete(leaveLimit) {
    return http.delete(`leave-limits/${leaveLimit.id}`, {
      data: { company_id: leaveLimit.company_id },
    })
  }
}
