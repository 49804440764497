import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default class AttachmentAddedEvent extends LeaveEvent {
  constructor(event) {
    super(event)
  }

  asText(viewer) {
    return `
      **${this.getActorName(viewer)}**
      ${this._getHaveOrHas(viewer)}
      uploaded a new attachment
   `
  }

  isDeleted() {
    return !!this.event.deleted_at
  }

  getResource() {
    return this.event.resource
  }

  getDeleterName(viewer) {
    if (viewer.id === this.event.resource.deleter_id) {
      return 'You'
    }

    return (
      this.event.resource.deleter.full_name +
      (this._isDeleterDeleted() ? ' (Archived)' : '')
    )
  }

  _isDeleterDeleted() {
    return this.event.resource.deleter.deleted_at != null
  }
}
