<template>
  <div
    class="tw-p-3 tw-space-x-2 tw-border tw-border-gray-220 tw-flex tw-items-center tw-justify-between tw-bg-white tw-rounded-lg"
    data-cy="leave-breakdown"
  >
    <LeaveDayDetails
      :day-overlap="dayOverlap"
      :multiline="true"
      no-overlaps-text="No overlaps"
      @show-overlap-leave-details="
        leaveKey => $emit('show-overlap-leave-details', leaveKey)
      "
    >
      <div
        class="tw-flex tw-text-gray-660 tw-text-base tw-font-medium tw-text-right"
      >
        {{ breakdownDuration }}
      </div>
    </LeaveDayDetails>
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import LeaveDayDetails from '@/components/requests/leave/LeaveDayDetails'
import DayOverlap from '@/models/reporting/DayOverlap'

export default {
  name: 'LeaveBreakdown',

  components: {
    LeaveDayDetails,
  },

  mixins: [FormatDate],

  props: {
    breakdown: {
      type: Object,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
    dayOverlap: {
      type: DayOverlap,
      required: true,
    },
  },

  computed: {
    breakdownDuration() {
      if (
        this.breakdown.type === 'working_day' &&
        this.breakdown.duration === 'custom'
      ) {
        const from = this.formatDateFromIsoToHourMinutes(
          this.breakdown.from,
          this.timezone
        )
        const to = this.formatDateFromIsoToHourMinutes(
          this.breakdown.to,
          this.timezone
        )

        return `${from} to ${to}`
      }

      return this.breakdown.period
    },
  },
}
</script>
