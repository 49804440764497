import { groupBy, orderBy } from 'lodash-es'
import moment from 'moment'
import ApprovedEvent from '@/components/requests/leave/events/ApprovedEvent'
import DeclinedEvent from '@/components/requests/leave/events/DeclinedEvent'
import CancelledEvent from '@/components/requests/leave/events/CancelledEvent'
import RequestedEvent from '@/components/requests/leave/events/RequestedEvent'
import RetractedEvent from '@/components/requests/leave/events/RetractedEvent'
import CommentEvent from '@/components/requests/leave/events/CommentEvent'
import UpdatedEvent from '@/components/requests/leave/events/UpdatedEvent'
import AttachmentAddedEvent from '@/components/requests/leave/events/AttachmentAddedEvent'
import OneOfBulkLeaveWasImportedEvent from '@/components/requests/leave/events/OneOfBulkLeaveWasImportedEvent'
import OneOfImportedLeaveWasApprovedEvent from '@/components/requests/leave/events/OneOfImportedLeaveWasApprovedEvent'
import CommentDeletedEvent from '@/components/requests/leave/events/CommentDeletedEvent'
import CommentRestoredEvent from '@/components/requests/leave/events/CommentRestoredEvent'

export default class Timeline {
  constructor(timeline) {
    this.timeline = timeline

    this._createEvents()
  }

  getLastEvent() {
    return this.events[this.eventCount - 1]
  }

  getLastLeaveEvent() {
    const leaveEvents = this.getLeaveEvents()

    return leaveEvents[leaveEvents.length - 1]
  }

  getLeaveEvents() {
    return this.events.filter(event => event.getResourceType() === 'leave')
  }

  get eventCount() {
    return this.events.length
  }

  getRecentEvents(count) {
    return this.events.slice(count * -1)
  }

  getEventsGroupedByDate() {
    return Object.entries(
      groupBy(this.events, event =>
        moment(event.createdAt).format('dddd DD MMMM YYYY')
      )
    ).map(([date, events]) => {
      return {
        date: date,
        events: orderBy(events, ['createdAt'], ['asc']),
      }
    })
  }

  _createEvents() {
    const eventMap = {
      leave_was_requested: RequestedEvent,
      leave_was_approved: ApprovedEvent,
      leave_was_cancelled: CancelledEvent,
      leave_was_rejected: DeclinedEvent,
      leave_was_updated: UpdatedEvent,
      leave_was_retracted: RetractedEvent,
      leave_comment_was_added: CommentEvent,
      leave_comment_was_deleted: CommentDeletedEvent,
      leave_comment_was_restored: CommentRestoredEvent,
      one_of_bulk_leave_was_requested: RequestedEvent,
      one_of_bulk_leave_was_approved: ApprovedEvent,
      leave_attachment_was_added: AttachmentAddedEvent,
      one_of_bulk_leave_was_imported: OneOfBulkLeaveWasImportedEvent,
      one_of_imported_leave_was_approved: OneOfImportedLeaveWasApprovedEvent,
    }

    this.events = []
    this.timeline.forEach(event => {
      this.events.push(new eventMap[event.event_class](event))
    })
  }
}
