var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full"},[_c('div',[_c('div',{staticClass:"tw-p-2 tw-flex tw-justify-between tw-w-full",attrs:{"data-cy":"leave-day-date"}},[_c('div',[_vm._v(" "+_vm._s(_vm.date)+" ")]),_c('PortalTarget',{attrs:{"name":_vm.portalTargetOneName,"multiple":""}}),_vm._t("default")],2)]),_c('div',{staticClass:"tw-flex tw-justify-end tw-w-full"},[(_vm.leaveLimitExceeded)?_c('div',{ref:_vm.tooltipTarget,staticClass:"tw-w-full",on:{"mouseover":_vm.showTooltip}},[_c('BaseAlert',{staticClass:"tw-w-full tw-flex",attrs:{"theme":"warning"},scopedSlots:_vm._u([{key:"right-content",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('UserAvatarList',{attrs:{"users":_vm.dayOverlap.leaveOwners(),"nonvisible-user-count":_vm.nonvisibleEmploymentCount}})],1)]},proxy:true}],null,false,2200612466)},[_vm._v(" Exceeds number of people allowed on leave. ")])],1):_vm._e(),_c('PortalTarget',{attrs:{"name":_vm.portalTargetTwoName,"multiple":""}}),(_vm.hasOverlapsFeature && !_vm.leaveLimitExceeded)?_c('Portal',{attrs:{"to":_vm.portalRenderTo,"order":1}},[_c('div',{ref:_vm.tooltipTarget,staticClass:"tw-flex tw-justify-end tw-w-full",attrs:{"data-cy":"user-on-leave-avatars"},on:{"mouseover":_vm.showTooltip}},[_c('div',[_c('UserAvatarList',{attrs:{"users":_vm.dayOverlap.leaveOwners(),"nonvisible-user-count":_vm.nonvisibleEmploymentCount}})],1)])]):_vm._e(),(
        _vm.hasOverlapsFeature &&
          !_vm.hasOverlapLeave &&
          !_vm.leaveLimitExceeded &&
          _vm.nonvisibleEmploymentCount > 0
      )?_c('Portal',{attrs:{"to":_vm.portalRenderTo,"order":2}},[_c('div',{staticClass:"tw-flex tw-items-center tw-space-x-1 tw-ml-1"},[_c('span',{staticClass:"tw-font-medium tw-text-purple-700",attrs:{"data-cy":"non-visible-overlaps"}},[_vm._v(" "+_vm._s(_vm.nonvisibleEmploymentCount)+" "+_vm._s(_vm._f("pluralize")('employee',_vm.nonvisibleEmploymentCount))+" on leave ")])])]):_vm._e(),(
        _vm.hasOverlapsFeature && !_vm.hasOverlapLeave && !_vm.hasNonvisibleEmployments
      )?_c('Portal',{attrs:{"to":_vm.portalRenderTo,"order":3}},[_c('div',{staticClass:"tw-flex tw-h-full tw-space-x-1 tw-items-center tw-ml-3"},[_c('SvgIcon',{staticClass:"tw-w-5 tw-h-5 tw-text-purple-500",attrs:{"name":"info-circle"}}),_c('span',{staticClass:"tw-w-full tw-font-medium tw-text-purple-700 tw-whitespace-no-wrap"},[_vm._v(" "+_vm._s(_vm.noOverlapsText)+" ")])],1)]):_vm._e()],1),(_vm.hasOverlapLeave)?_c('div',{ref:"overlapLeavePanel",staticClass:"tw-hidden"},[_c('div',{staticClass:"tw-items-start tw-p-3 tw-border tw-rounded-md tw-border-gray-660 tw-bg-gray-660 leave-container"},[_c('OverlapLeave',{attrs:{"overlap-leaves":_vm.dayOverlap.leaves},on:{"show-overlap-leave-details":_vm.showOverlapLeaveDetails}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }