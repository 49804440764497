import { Billing } from '@/api'
import BillingPlanCollection from '@/models/Billing/BillingPlanCollection'

const initialState = {
  plans: new BillingPlanCollection(),
}

export const state = {
  ...initialState,
  initialState() {
    return initialState
  },
}

export const mutations = {
  SET_BILLING_PLANS(state, plans) {
    state.plans = plans
  },
}

export const actions = {
  async fetchBillingPlans({ state, commit }) {
    if (state.plans.isEmpty()) {
      commit('SET_BILLING_PLANS', await Billing.fetchBillingPlans())
    }
  },
}
