<template>
  <form class="tw-w-full tw-flex tw-flex-col tw-h-full" novalidate>
    <Loading
      class="tw-outline-none"
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active="pageLoading"
    />

    <Portal to="overtime-panel-header-content">
      <RequestPanelHeader
        icon="clock-outline"
        title="Request Overtime"
        :selected-employments="selectedEmployments"
        @show-more-employments="openMultipleEmploymentPicker"
      />
    </Portal>

    <div class="sm:tw-flex sm:tw-flex-1">
      <div
        class="tw-w-full sm:tw-w-1/2 sm:tw-pr-4 sm:tw-border-r sm:tw-border-gray-220"
      >
        <div class="tab-column tw-h-full tw-overflow-y-auto">
          <InputGroup
            v-if="canRequestForMultipleEmployments"
            label="Team Member"
            :required="true"
          >
            <MultipleEmploymentPicker
              v-if="activeCompany.hasFeature(Feature.MultiPersonRequests)"
              ref="multipleEmploymentPicker"
              :value="selectedEmployments"
              :employments="availableEmployments"
              @input="setSelectedEmployments"
              @close="fetchAllowancesAndDraftOvertime"
            />
            <EmploymentPicker
              v-else
              v-model="selectedEmployment"
              :options="availableEmployments"
              :searchable="true"
              :show-avatars="true"
              :group-by-department="true"
              :max-height="300"
            />
          </InputGroup>

          <InputGroup label="Request Type" :required="false">
            <div class="tw-flex tw-items-center">
              <RequestTypePicker
                :value="defaultOvertimeRequestType.id"
                :options="overtimeRequestTypes"
                disabled
                data-vv-name="type"
                data-vv-as="overtime request type"
                data-cy="overtime-request-type"
                tabindex="2"
              />
            </div>
          </InputGroup>

          <InputGroup
            label="Dates"
            :required="true"
            :has-error="errors.has('dateRange')"
            :input-error="errors.first('dateRange')"
          >
            <DateRangePicker
              v-validate="'required'"
              data-vv-name="date"
              :value="form.date_range"
              :min-date="selectableMinDate"
              :max-date="selectableMaxDate"
              :format="dateFormat"
              class="form-control"
              data-cy="overtime-date-cal"
              tabindex="1"
              @input="setDateRange"
            />

            <DatesOverlapOnDifferentCalendarsError
              v-if="datesOverlapOnDifferentCalendars"
            />

            <RequestingOutsideOfEmploymentPeriodError
              v-if="requestingOutsideOfEmploymentPeriod"
            />

            <CalendarNotSetupError v-if="doesntHaveCalendarToRequestOvertime" />
          </InputGroup>

          <InputGroup
            label="Description"
            label-for="reason"
            :required="true"
            :has-error="errors.has('reason')"
            :input-error="errors.first('reason')"
          >
            <textarea
              id="reason"
              v-model="form.reason"
              v-validate="'required|max:2000'"
              v-focus
              data-vv-name="reason"
              data-vv-as="description"
              data-cy="description"
              class="form-control tw-resize-none"
              placeholder="Enter a description..."
              type="text"
              autocomplete="off"
              rows="5"
              tabindex="2"
            />
          </InputGroup>

          <InputGroup label="Attachments" class="sm:tw-mb-0">
            <AttachmentInput @update-attachments="updateAttachments" />
          </InputGroup>
        </div>
      </div>

      <div class="tw-w-full sm:tw-w-1/2 sm:tw-pl-4 tw-flex tw-flex-col">
        <div class="tab-column tw-h-full tw-overflow-y-auto">
          <Loading
            class="tw-outline-none"
            :blur="null"
            loader="dots"
            :is-full-page="false"
            :active="dataLoading"
          />

          <div
            v-if="selectedEmployments.length && overtimeRequests"
            class="tw-flex tw-flex-col tw-h-full"
          >
            <div class="tw-flex tw-flex-col tw-flex-1">
              <template v-if="isSingleEmploymentOvertimeRequest">
                <div class="tw-mb-2 tw-font-medium tw-text-base tw-text-black">
                  Overtime Details
                </div>

                <OvertimeBreakdowns
                  class="tw-h-full tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220 tw-flex tw-flex-1"
                  :allowance-unit="overtimeRequests.allowanceUnit"
                  :minutes-per-working-day="
                    overtimeRequests.minutesPerWorkingDay
                  "
                  :breakdowns="overtimeRequests.breakdowns"
                  @breakdown-amount-changed="breakdownAmountChanged"
                  @breakdown-unit-changed="breakdownUnitChanged"
                />
              </template>

              <TabsWrapper
                v-else-if="isMultipleEmploymentOvertimeRequest"
                ref="tabs"
                class="tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220 tw-flex"
                @changed="setActiveTab"
              >
                <BaseTab
                  id="overtime-details"
                  name="Overtime Details"
                  class="tw-flex"
                >
                  <OvertimeBreakdowns
                    class="tw-flex-1"
                    :is-tabs-visible="true"
                    :allowance-unit="overtimeRequests.allowanceUnit"
                    :minutes-per-working-day="
                      overtimeRequests.minutesPerWorkingDay
                    "
                    :breakdowns="overtimeRequests.breakdowns"
                    @breakdown-amount-changed="breakdownAmountChanged"
                    @breakdown-unit-changed="breakdownUnitChanged"
                  />
                </BaseTab>

                <BaseTab
                  v-if="overtimeAllowances"
                  id="employee-allowances"
                  name="Employee Allowances"
                  class="tw-flex"
                >
                  <ScrollableContent>
                    <EmploymentOvertimeAllowancesTable
                      class="tw-overflow-hidden"
                      :overtime-allowances="overtimeAllowances.all"
                    />
                  </ScrollableContent>
                </BaseTab>
              </TabsWrapper>
            </div>

            <div class="tw-mt-6">
              <div v-if="showAllowanceMonitor">
                <div class="tw-mb-2 tw-font-medium tw-text-base tw-text-black">
                  Allowance Summary
                </div>

                <AllowanceIncrementMonitor
                  v-if="overtimeAllowances"
                  :allowances="overtimeAllowances.summary"
                  class="tw-flex-none"
                />
              </div>

              <BaseAlert
                v-if="overtimeAllowances.summary.hasUnlimitedAllowances"
                theme="info"
                class="tw-mt-2"
              >
                Allowance is not affected, because your allowance is set up as
                unlimited
              </BaseAlert>

              <div
                v-if="isMultipleEmploymentOvertimeRequest"
                class="tw-mt-2 tw-text-right tw-text-gray-700"
              >
                A range is shown as allowances can vary by employee.
                <button
                  v-if="!activeTabIsEmployeeAllowances"
                  type="button"
                  class="btn-link"
                  @click="changeTab('#employee-allowances')"
                >
                  See details
                </button>
              </div>
            </div>
          </div>

          <div v-else class="tw-flex tw-flex-col tw-h-full">
            <NoData
              :message="
                selectedEmployments.length
                  ? 'No dates selected'
                  : 'No employee selected'
              "
            />
          </div>
        </div>
      </div>
    </div>

    <Portal to="overtime-panel-footer-content">
      <OvertimeFooterPanel
        :submit-button-disabled="!companyHasOvertimeFeature || !isValid"
        :submit-button-loading="submitting"
        :overtime-request-approval-microcopy="overtimeRequestApprovalMicrocopy"
        :spinner-button-tooltip-message="spinnerButtonTooltipMessage"
        @send-overtime-request="requestOvertime"
        @hide="$emit('hide')"
      />
    </Portal>
  </form>
</template>

<script>
import Loading from 'vue-loading-overlay'
import DateRangePicker from '@/components/DateRangePicker'
import ValidatesForm from '@/mixins/ValidatesForm'
import FormatDate from '@/mixins/FormatDate'
import FormatNumbers from '@/mixins/FormatNumbers'
import LeavePermissions from '@/mixins/LeavePermissions'
import HandleWorkingSchedule from '@/mixins/HandleWorkingSchedule'
import InputGroup from '@/components/InputGroup'
import AttachmentInput from '@/components/AttachmentInput'
import AllowanceIncrementMonitor from '@/components/requests/overtime/AllowanceIncrementMonitor'
import EmploymentOvertimeAllowancesTable from '@/components/requests/overtime/EmploymentOvertimeAllowancesTable'
import MultipleEmploymentPicker from '@/components/pickers/MultipleEmploymentPicker'
import TabsWrapper from '@/components/TabsWrapper'
import BaseTab from '@/components/BaseTab'
import NoData from '@/components/NoData'
import BaseAlert from '@/components/BaseAlert'
import RequestPanelHeader from '@/components/requests/RequestPanelHeader'
import OvertimeFooterPanel from '@/components/requests/overtime/OvertimeFooterPanel'
import OvertimeRequests from '@/models/overtime/drafting/OvertimeRequests'
import OvertimeBreakdowns from '@/components/requests/overtime/OvertimeBreakdowns'
import OvertimeRequestMicrocopy from '@/components/requests/overtime/OvertimeRequestMicrocopy'
import {
  filter,
  head,
  join,
  map,
  maxBy,
  minBy,
  omit,
  reject,
  size,
  some,
} from 'lodash-es'
import OvertimeAllowance from '@/models/overtime/OvertimeAllowance'
import { Overtimes, AllowanceReport, Calendars } from '@/api'
import ScrollableContent from '@/components/ScrollableContent'
import DatesOverlapOnDifferentCalendarsError from '@/components/requests/overtime/DatesOverlapOnDifferentCalendarsError'
import RequestingOutsideOfEmploymentPeriodError from '@/components/requests/overtime/RequestingOutsideOfEmploymentPeriodError'
import CalendarNotSetupError from '@/components/requests/CalendarNotSetupError'
import RequestTypePicker from '@/components/pickers/RequestTypePicker'
import CalendarCollection from '@/models/company/CalendarCollection'
import Feature from '@/models/Billing/Feature'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'

export default {
  name: 'OvertimeForm',

  components: {
    EmploymentPicker,
    RequestTypePicker,
    CalendarNotSetupError,
    RequestingOutsideOfEmploymentPeriodError,
    DatesOverlapOnDifferentCalendarsError,
    ScrollableContent,
    BaseAlert,
    AllowanceIncrementMonitor,
    EmploymentOvertimeAllowancesTable,
    Loading,
    AttachmentInput,
    OvertimeBreakdowns,
    DateRangePicker,
    InputGroup,
    MultipleEmploymentPicker,
    TabsWrapper,
    BaseTab,
    NoData,
    RequestPanelHeader,
    OvertimeFooterPanel,
  },

  mixins: [
    ValidatesForm,
    FormatDate,
    FormatNumbers,
    LeavePermissions,
    HandleWorkingSchedule,
  ],

  props: {
    period: {
      type: Array,
      required: true,
    },

    employee: {
      type: Object,
      default: null,
    },

    employments: {
      type: Array,
      required: true,
    },

    isOvertimeTabActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      activeTab: null,
      loading: false,
      submitting: false,
      pageLoading: true,
      dataLoading: false,
      attachments: [],
      calendars: new CalendarCollection(),
      form: {
        reason: '',
        date_range: [],
        company_id: '',
      },
      selectedEmployments: [],
      employmentAllowances: null,
      overtimeRequests: null,
      overtimeAllowances: null,
    }
  },

  computed: {
    companyHasOvertimeFeature() {
      return this.activeCompany.hasFeature(Feature.TimeOffInLieu)
    },

    Feature() {
      return Feature
    },

    isValid() {
      return (
        this.valid &&
        !this.requestHasErrors &&
        this.selectedEmployment &&
        !this.loading &&
        !this.submitting
      )
    },

    requestHasErrors() {
      return (
        this.datesOverlapOnDifferentCalendars ||
        this.requestingOutsideOfEmploymentPeriod ||
        this.doesntHaveCalendarToRequestOvertime
      )
    },

    showAllowanceMonitor() {
      return !this.doesntHaveCalendarToRequestOvertime
    },

    datesOverlapOnDifferentCalendars() {
      return this.overtimeRequests?.datesOverlapOnDifferentCalendars
    },

    requestingOutsideOfEmploymentPeriod() {
      return this.overtimeRequests?.requestingOutsideOfEmploymentPeriod
    },

    doesntHaveCalendarToRequestOvertime() {
      return this.overtimeRequests?.doesntHaveCalendarToRequestOvertime
    },

    canRequestForMultipleEmployments() {
      return (
        this.activeEmployment.is_admin ||
        !!this.activeEmployment.subordinates.length
      )
    },

    activeTabIsEmployeeAllowances() {
      return this.activeTab?.hash === '#employee-allowances'
    },

    selectedEmployment: {
      get() {
        return head(this.selectedEmployments)
      },
      set(employment) {
        this.selectedEmployments = [employment]

        this.fetchAllowancesAndDraftOvertime()
      },
    },

    availableEmployments() {
      return filter(this.employments, employment => {
        return (
          this.activeEmployment.is_admin ||
          employment.id === this.activeEmployment.id ||
          this.canApprove({ owner_id: employment.id }, this.activeEmployment)
        )
      })
    },

    isSingleEmploymentOvertimeRequest() {
      return this.selectedEmployments.length === 1
    },

    isMultipleEmploymentOvertimeRequest() {
      return this.selectedEmployments.length > 1
    },

    overtimeSuccessMessage() {
      return this.approvalNeeded
        ? 'Your request has been submitted for approval.'
        : 'Your overtime has been booked.'
    },

    approvalNeeded() {
      return (
        this.isSingleEmploymentOvertimeRequest && !this.onlyApproverIsTheCreator
      )
    },

    onlyApproverIsTheCreator() {
      if (!this.selectedEmployment) {
        return false
      }

      let selectedEmploymentApproves = this.overtimeRequests.items[0].owner
        .approvers

      if (size(selectedEmploymentApproves) > 1) {
        return false
      }

      return some(selectedEmploymentApproves, superior => {
        return superior.id === this.activeEmployment.id
      })
    },

    dateFormat() {
      return this.getFormatOfDayReadableDayNumberShortMonthYear()
    },

    requestedFrom() {
      return this.form.date_range[0].utc(true).format()
    },

    requestedTo() {
      return this.form.date_range[1].utc(true).format()
    },

    selectableMinDate() {
      if (!this.selectedEmployment) {
        return null
      }

      if (this.selectedEmployment.start_date) {
        return this.selectedEmployment.start_date
      }

      if (!this.calendars.length()) {
        return null
      }

      const visibleCalendars = reject(this.calendars.all(), 'hidden')

      return minBy(visibleCalendars, 'start_date').start_date.format()
    },

    selectableMaxDate() {
      if (!this.selectedEmployment) {
        return null
      }

      if (this.selectedEmployment.end_date) {
        return this.selectedEmployment.end_date
      }

      if (!this.calendars.length()) {
        return null
      }

      const visibleCalendars = reject(this.calendars.all(), 'hidden')

      return maxBy(visibleCalendars, 'end_date').end_date.format()
    },

    overtimeRequestApprovalMicrocopy() {
      if (!this.overtimeRequests) return null

      return new OvertimeRequestMicrocopy(
        this.activeEmployment,
        this.overtimeRequests.items
      )
    },

    overtimeRequestTypes() {
      return [
        {
          id: this.defaultOvertimeRequestType.id,
          name: this.defaultOvertimeRequestType.title,
          colour: this.defaultOvertimeRequestType.color,
          is_default: true,
        },
      ]
    },

    defaultOvertimeRequestType() {
      let toilVisibilitySettings = this.activeEmployment.company
        .time_off_in_lieu_visibility_settings

      if (!toilVisibilitySettings) {
        toilVisibilitySettings = {
          id: '1',
          title: 'Earned Time Off In Lieu',
          color: '#1CD1A1',
        }
      }

      return toilVisibilitySettings
    },

    spinnerButtonTooltipMessage() {
      let requiredFields = []

      if (this.selectedEmployments.length === 0) {
        requiredFields.push('Team Member')
      }

      if (this.form.reason.trim() === '') {
        requiredFields.push('Description')
      }

      if (this.form.date_range.length === 0) {
        requiredFields.push('Dates')
      }

      const listFormatter = new Intl.ListFormat('en', {
        style: 'long',
        type: 'conjunction',
      })

      const fieldNames = listFormatter.format(requiredFields)

      return `${fieldNames} ${
        requiredFields.length === 1 ? ' is' : ' are'
      } required to request an overtime`
    },
  },

  watch: {
    isOvertimeTabActive: function(v) {
      if (v && this.overtimeRequests === null) {
        this.draftOvertime()
      }
    },
  },

  async created() {
    this.form.date_range = this.period
    this.form.company_id = this.activeCompany.id

    await this.fetchCalendars()
    this.selectedEmployments = [this.employee]
    await this.fetchEmploymentAllowances()

    this.pageLoading = false
  },

  methods: {
    async fetchCalendars() {
      try {
        this.calendars = await Calendars.all(this.$route.query)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchEmploymentAllowances() {
      try {
        if (!this.selectedEmployments.length || !this.form.date_range.length) {
          return
        }

        this.employmentAllowances = (
          await AllowanceReport.getDetailsAllowances({
            company_id: this.activeCompany.id,
            period: `${this.form.date_range[0].format(
              'YYYY-MM-DD'
            )},${this.form.date_range[1].format('YYYY-MM-DD')}`,
            allowance_type: this.activeCompany.current_time_off_in_lieu
              .allowance_type_id,
            employee: this.selectedEmployments.length
              ? join(map(this.selectedEmployments, 'id'))
              : '-',
            per_page: this.selectedEmployments.length,
          })
        ).items()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async draftOvertime() {
      if (!this.selectedEmployments.length || !this.form.date_range.length) {
        return false
      }

      if (!this.pageLoading) {
        this.dataLoading = true
      }

      this.submitting = true

      try {
        const { data } = await Overtimes.draft({
          company_id: this.activeCompany.id,
          owner_id: map(this.selectedEmployments, 'id'),
          from: this.form.date_range[0].format(),
          to: this.form.date_range[1].format(),
        })

        data.employments = data.employments.map(draftedEmploymentData => {
          return {
            ...this.selectedEmployments.find(
              employment => employment.id === draftedEmploymentData.owner_id
            ),
            ...draftedEmploymentData,
          }
        })

        this.overtimeRequests = new OvertimeRequests(data, this.activeCompany)
        this.setOvertimeAllowances()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.submitting = false
      this.dataLoading = false
      this.pageLoading = false
    },

    breakdownUnitChanged: function(newBreakdown) {
      this.overtimeRequests.updateBreakdownUnit(newBreakdown)
      this.setOvertimeAllowances()
    },

    breakdownAmountChanged: function(breakdown) {
      this.overtimeRequests.breakdownAmountChanged(breakdown)
      this.setOvertimeAllowances()
    },

    setOvertimeAllowances() {
      if (this.overtimeRequests) {
        this.overtimeAllowances = new OvertimeAllowance(
          this.overtimeRequests.items,
          this.employmentAllowances
        )
      }
    },

    openMultipleEmploymentPicker() {
      this.$refs.multipleEmploymentPicker.open()
    },

    setSelectedEmployments(selectedEmployments) {
      this.selectedEmployments = selectedEmployments
    },

    async fetchAllowancesAndDraftOvertime() {
      this.dataLoading = true

      await this.fetchEmploymentAllowances()

      await this.draftOvertime()

      this.setOvertimeAllowances()

      this.dataLoading = false
    },

    updateAttachments(attachments) {
      this.attachments = attachments
    },

    async setDateRange(period) {
      this.form.date_range = period.map(date => date.clone().utc(true))

      await this.fetchEmploymentAllowances()
      this.draftOvertime()
    },

    async requestOvertime() {
      await this.validate()

      if (!this.valid) return

      this.submitting = true

      let breakdowns = []
      this.overtimeRequests.items.forEach(overtimeRequest => {
        breakdowns.push({
          owner_id: overtimeRequest.owner.id,
          breakdowns: overtimeRequest.breakdowns.map(breakdown =>
            breakdown.toJson()
          ),
        })
      })

      try {
        const response = await Overtimes.request({
          ...omit(this.form, ['date_range']),
          from: this.requestedFrom,
          to: this.requestedTo,
          overtime_breakdowns: breakdowns,
        })

        if (this.attachments.length) {
          await this.saveOvertimeAttachments(response.data)
        }

        this.success(this.overtimeSuccessMessage)

        this.$emit('hide')

        this.$emit('overtime-requested')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.submitting = false
    },

    async saveOvertimeAttachments(overtimeIds) {
      for (const overtimeId of overtimeIds) {
        for (const attachment of this.attachments) {
          try {
            await Overtimes.saveAttachment(overtimeId, {
              company_id: this.activeCompany.id,
              attachment_id: attachment.id,
              file_name: attachment.name,
            })
          } catch ({ response }) {
            this.validateFromResponse(response)
          }
        }
      }
    },

    changeTab(tabHash) {
      this.$refs.tabs.selectTab(tabHash)
    },

    setActiveTab(selectedTab) {
      this.activeTab = selectedTab.tab
    },
  },
}
</script>

<style lang="scss" scoped>
@screen sm {
  .tab-column {
    max-height: calc(100vh - 224px);
  }
}
</style>
