<template>
  <div class="tw-mb-4 tw-space-y-4">
    <LeaveDayDetails
      :day-overlap="dayOverlap"
      :multiline="false"
      no-overlaps-text="Nobody on leave"
      @show-overlap-leave-details="
        leaveKey => $emit('show-overlap-leave-details', leaveKey)
      "
    />
  </div>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import LeaveDayDetails from '@/components/requests/leave/LeaveDayDetails'
import DayOverlap from '@/models/reporting/DayOverlap'

export default {
  name: 'HandoverBreakdown',

  components: {
    LeaveDayDetails,
  },

  mixins: [FormatDate],

  props: {
    dayOverlap: {
      type: DayOverlap,
      required: true,
    },
  },
}
</script>
