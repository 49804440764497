<template>
  <form class="tw-w-full tw-flex tw-flex-col tw-h-full" novalidate>
    <Loading
      class="tw-outline-none"
      :blur="null"
      loader="dots"
      :is-full-page="false"
      :active="pageLoading"
    />

    <Portal to="leave-panel-header-content">
      <RequestPanelHeader
        icon="umbrella"
        title="Request Leave"
        :selected-employments="selectedEmployments"
        @show-more-employments="openMultipleEmploymentPicker"
      />
    </Portal>

    <div class="sm:tw-flex sm:tw-flex-1">
      <div
        class="tw-w-full sm:tw-w-1/2 sm:tw-pr-4 sm:tw-border-r sm:tw-border-gray-220"
      >
        <div class="tab-column tw-h-full tw-overflow-y-auto">
          <InputGroup
            v-if="canRequestForMultipleEmployments"
            label="Team Member"
            :required="true"
          >
            <MultipleEmploymentPicker
              v-if="activeCompany.hasFeature(Feature.MultiPersonRequests)"
              ref="multipleEmploymentPicker"
              :value="selectedEmployments"
              :employments="availableEmployments"
              @input="setSelectedEmployments"
              @close="fetchEmployeesAllowancesAndDraftLeave"
            />
            <EmploymentPicker
              v-else
              v-model="selectedEmployment"
              :options="availableEmployments"
              :searchable="true"
              :show-avatars="true"
              :group-by-department="true"
              :max-height="300"
            />
          </InputGroup>

          <InputGroup
            label="Leave Type"
            :required="true"
            :has-error="errors.has('leaveType')"
            :input-error="errors.first('leaveType')"
          >
            <div class="tw-flex tw-items-center">
              <RequestTypePicker
                v-validate:selectedLeaveType="'required'"
                :value="selectedLeaveType"
                :options="leaveTypes"
                data-vv-name="type"
                data-vv-as="leave type"
                data-cy="leave-type"
                tabindex="2"
                @input="setLeaveType"
              />

              <div class="tw-flex tw-items-center tw-space-x-5">
                <input
                  id="is_private"
                  v-model="form.is_private"
                  class="magic-checkbox"
                  data-cy="is-private"
                  name="is_private"
                  type="checkbox"
                />
                <label
                  for="is_private"
                  class="tw-select-none magic-checkbox-label"
                >
                  <label
                    for="is_private"
                    class="tw-select-none magic-checkbox-label"
                  >
                    <span class="tw-flex tw-items-center">
                      <span>Private</span>

                      <ExtraInfo icon="info-circle">
                        <div class="tw-p-4 tw-w-48">
                          Leave which is marked as private will appear Grey on
                          the shared wall chart for all users except
                          administrators and yourself.
                        </div>
                      </ExtraInfo>
                    </span>
                  </label>
                </label>
              </div>
            </div>
          </InputGroup>

          <InputGroup
            label="Dates"
            :required="true"
            :has-error="errors.has('dateRange')"
            :input-error="errors.first('dateRange')"
          >
            <DateRangePicker
              v-validate="'required'"
              data-vv-name="date"
              :value="form.date_range"
              :min-date="selectableMinDate"
              :max-date="selectableMaxDate"
              :format="dateFormat"
              data-cy="leave-date-cal"
              class="form-control"
              tabindex="3"
              @input="setDateRange"
            />

            <CalendarNotSetupError v-if="doesntHaveCalendarToRequestLeave" />

            <CrossAllowanceYearBoundaryError
              v-if="datesOverlapOnDifferentCalendars"
            />

            <RequestLeaveOnPastDateWarning
              v-if="!datesOverlapOnDifferentCalendars && containPastDates"
            />
          </InputGroup>

          <InputGroup
            label="Description"
            label-for="reason"
            :has-error="errors.has('reason')"
            :input-error="errors.first('reason')"
          >
            <textarea
              id="reason"
              v-model="form.reason"
              v-validate="'max:2000'"
              v-focus
              data-vv-name="reason"
              data-vv-as="description"
              data-cy="description"
              class="form-control tw-resize-none"
              placeholder="Enter a description..."
              type="text"
              autocomplete="off"
              rows="5"
              tabindex="2"
            />
          </InputGroup>

          <InputGroup label="Attachments" class="sm:tw-mb-0">
            <AttachmentInput @update-attachments="updateAttachments" />
          </InputGroup>
        </div>
      </div>

      <div class="tw-w-full sm:tw-w-1/2 sm:tw-pl-4 tw-flex tw-flex-col">
        <div class="tab-column tw-h-full tw-flex tw-flex-col">
          <Loading
            class="tw-outline-none"
            :blur="null"
            loader="dots"
            :is-full-page="false"
            :active="dataLoading"
          />

          <div
            v-if="
              selectedEmployments.length &&
                form.date_range.length &&
                draftedLeave
            "
            class="tw-flex tw-flex-col tw-h-full"
          >
            <div class="tw-flex tw-flex-col tw-flex-1">
              <template v-if="isSingleEmploymentLeaveRequest">
                <div
                  class="tw-mb-2 tw-font-medium tw-text-base tw-text-black"
                  data-cy="leave-form-title"
                >
                  Leave Details
                </div>
                <div
                  class="tw-flex tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220 tw-flex-grow"
                >
                  <Loading
                    class="tw-outline-none"
                    :blur="null"
                    loader="dots"
                    :is-full-page="false"
                    :active="isDrafting"
                  />
                  <LeaveRequestBreakdowns
                    :breakdowns="draftedLeave.breakdowns"
                    :leave-type="selectedLeaveType"
                    :show-custom-duration="draftedLeave.showCustomDuration"
                    :is-single-employment-request="draftedLeave.isSingleRequest"
                    :employments="employmentsWithWorkingSchedules"
                    @duration-selected="handleDurationSelected"
                    @show-overlap-leave-details="$emit('hide')"
                  />
                </div>
              </template>

              <TabsWrapper
                v-else-if="isMultipleEmploymentLeaveRequest"
                ref="tabs"
                class="tw-p-4 tw-border tw-rounded-lg tw-bg-gray-102 tw-border-gray-220"
                @changed="setActiveTab"
              >
                <BaseTab
                  id="leave-details"
                  name="Leave Details"
                  class="tw-flex"
                >
                  <LeaveRequestBreakdowns
                    :is-tabs-visible="true"
                    :breakdowns="draftedLeave.breakdowns"
                    :leave-type="selectedLeaveType"
                    :show-custom-duration="draftedLeave.showCustomDuration"
                    :is-single-employment-request="draftedLeave.isSingleRequest"
                    :employments="employmentsWithWorkingSchedules"
                    @duration-selected="handleDurationSelected"
                    @show-overlap-leave-details="$emit('hide')"
                  />
                </BaseTab>

                <BaseTab
                  v-if="selectedLeaveTypeHasAllowances"
                  id="employee-allowances"
                  name="Employee Allowances"
                  cypress-attribute="tab-emp-allowances"
                  class="tw-flex"
                >
                  <ScrollableContent>
                    <EmploymentLeaveAllowancesTable
                      class="tw-overflow-hidden"
                      :leave-allowances="leaveAllowances.getAllowances()"
                    />
                  </ScrollableContent>
                </BaseTab>
              </TabsWrapper>
            </div>

            <div v-if="selectedLeaveTypeHasAllowances" class="tw-mt-6">
              <div v-if="showAllowanceSummary">
                <div class="tw-mb-2 tw-font-medium tw-text-base tw-text-black">
                  Allowance Summary
                  <PortalTarget name="summary-view-switcher" />
                </div>

                <AllowanceDecrementMonitor
                  v-if="leaveAllowances"
                  class="tw-flex-none"
                  :allowances="leaveAllowances"
                />
              </div>

              <div
                v-if="isMultipleEmploymentLeaveRequest"
                class="tw-mt-2 tw-text-right tw-text-gray-700"
              >
                A range is shown as allowances can vary by employee.

                <button
                  v-if="
                    !hasRemainingAllowancesExceeded &&
                      !activeTabIsEmployeeAllowances
                  "
                  type="button"
                  class="btn-link"
                  @click="changeTab('#employee-allowances')"
                >
                  See details
                </button>
              </div>
            </div>

            <BaseAlert
              v-if="
                hasRemainingAllowancesExceeded &&
                  !doesntHaveCalendarToRequestLeave
              "
              theme="danger"
              class="tw-mt-2"
            >
              <span v-if="isSingleEmploymentLeaveRequest">
                {{ exceedsRemainingEmploymentAllowanceText }}
              </span>

              <span v-if="isMultipleEmploymentLeaveRequest">
                Not all employees have enough allowance for this request.

                <button
                  type="button"
                  class="btn-link tw-text-red-800 tw-font-medium"
                  @click="changeTab('#employee-allowances')"
                >
                  See details
                </button>
              </span>
            </BaseAlert>

            <UnlimitedAllowanceTypeInfo
              v-if="selectedLeaveTypeDoesntHaveAllowances"
              :leave-type="selectedLeaveType"
            />

            <BaseAlert
              v-if="
                isSingleEmploymentLeaveRequest &&
                  selectedLeaveTypeHasAllowances &&
                  hasUnlimitedAllowances
              "
              theme="info"
              class="tw-mt-2"
            >
              {{ employmentAllowanceUnlimitedText }}
            </BaseAlert>
          </div>

          <div v-else class="tw-flex tw-flex-col tw-h-full">
            <NoData
              :message="
                selectedEmployments.length
                  ? 'No dates selected'
                  : 'No employee selected'
              "
            />
          </div>
        </div>
      </div>
    </div>

    <Portal v-if="draftedLeave" to="leave-request-breakdown-total-text">
      <LeaveRequestBreakdownTotalText
        :total-durations-in-units="draftedLeave.totalSelectedDurationsInUnits"
        :minutes-per-working-day="minutesPerWorkingDay"
        :show-tooltip="isMultipleEmploymentLeaveRequest"
      />
    </Portal>

    <Portal to="leave-panel-footer-content">
      <RequestPanelFooter
        :submit-button-disabled="
          !valid || requestHasErrors || submitting || !selectedEmployment
        "
        :submit-button-loading="submitting"
        :microcopy-text="microcopyText"
        @send-request="requestLeave"
        @cancel="$emit('hide')"
      />
    </Portal>
  </form>
</template>

<script>
import BaseAlert from '@/components/BaseAlert'
import DateRangePicker from '@/components/DateRangePicker'
import ExtraInfo from '@/components/ExtraInfo'
import InputGroup from '@/components/InputGroup'
import MultipleEmploymentPicker from '@/components/pickers/MultipleEmploymentPicker'
import AttachmentInput from '@/components/AttachmentInput'
import TabsWrapper from '@/components/TabsWrapper'
import BaseTab from '@/components/BaseTab'
import NoData from '@/components/NoData'
import RequestTypePicker from '@/components/pickers/RequestTypePicker'
import RequestPanelHeader from '@/components/requests/RequestPanelHeader'
import RequestPanelFooter from '@/components/requests/RequestPanelFooter'
import EmploymentLeaveAllowancesTable from '@/components/requests/leave/EmploymentLeaveAllowancesTable'
import AllowanceDecrementMonitor from '@/components/requests/leave/AllowanceDecrementMonitor'
import LeaveRequestBreakdowns from '@/components/requests/leave/LeaveRequestBreakdowns'
import ValidatesForm from '@/mixins/ValidatesForm'
import FormatDate from '@/mixins/FormatDate'
import FormatNumbers from '@/mixins/FormatNumbers'
import LeavePermissions from '@/mixins/LeavePermissions'
import Loading from 'vue-loading-overlay'
import {
  map,
  head,
  omit,
  filter,
  clone,
  some,
  first,
  minBy,
  maxBy,
  reject,
  join,
  includes,
} from 'lodash-es'
import { Calendars, AllowanceReport, Leaves, LeaveTypes } from '@/api'
import EmploymentAllowanceUnlimitedText from '@/components/requests/EmploymentAllowanceUnlimitedText'
import ExceedsRemainingEmploymentAllowanceText from '@/components/requests/ExceedsRemainingEmploymentAllowanceText'
import CrossAllowanceYearBoundaryError from '@/components/requests/CrossAllowanceYearBoundaryError'
import CalendarNotSetupError from '@/components/requests/CalendarNotSetupError'
import RequestLeaveOnPastDateWarning from '@/components/requests/leave/RequestLeaveOnPastDateWarning'
import UnlimitedAllowanceTypeInfo from '@/components/requests/leave/UnlimitedAllowanceTypeInfo'
import LeaveRequestBreakdownTotalText from '@/components/requests/leave/LeaveRequestBreakdownTotalText'
import HandleWorkingSchedule from '@/mixins/HandleWorkingSchedule'
import LeaveAllowances from '@/models/leave/LeaveAllowance'
import LeaveRequestMicrocopy from '@/components/requests/leave/LeaveRequestMicrocopy'
import ScrollableContent from '@/components/ScrollableContent'
import CalendarCollection from '@/models/company/CalendarCollection'
import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'
import EmploymentPicker from '@/components/pickers/EmploymentPicker'
import Feature from '@/models/Billing/Feature'

export default {
  name: 'LeaveForm',

  components: {
    EmploymentPicker,
    ScrollableContent,
    CalendarNotSetupError,
    UnlimitedAllowanceTypeInfo,
    RequestLeaveOnPastDateWarning,
    CrossAllowanceYearBoundaryError,
    BaseAlert,
    AttachmentInput,
    Loading,
    ExtraInfo,
    InputGroup,
    DateRangePicker,
    RequestTypePicker,
    MultipleEmploymentPicker,
    TabsWrapper,
    BaseTab,
    NoData,
    LeaveRequestBreakdowns,
    RequestPanelHeader,
    RequestPanelFooter,
    EmploymentLeaveAllowancesTable,
    AllowanceDecrementMonitor,
    LeaveRequestBreakdownTotalText,
  },

  mixins: [
    ValidatesForm,
    FormatDate,
    FormatNumbers,
    LeavePermissions,
    HandleWorkingSchedule,
  ],

  props: {
    period: {
      type: Array,
      required: true,
    },

    employee: {
      type: Object,
      default: null,
    },

    employments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: null,
      submitting: false,
      pageLoading: true,
      isDrafting: false,
      dataLoading: false,
      leaveAllowances: null,
      attachments: [],
      calendars: new CalendarCollection(),
      leaveTypes: [],
      form: {
        reason: null,
        type_id: null,
        date_range: [...this.period],
        company_id: null,
        is_private: false,
      },
      selectedEmployments: [],
      employmentAllowances: new EmploymentAllowanceCollection([]),
      draftedLeave: null,
      selectedBreakdowns: [],
    }
  },

  computed: {
    Feature() {
      return Feature
    },

    employmentsWithWorkingSchedules() {
      let selectedEmploymentsKeys = this.selectedEmployments.map(
        employment => employment.id
      )

      return this.employments.filter(function(employment) {
        if (includes(selectedEmploymentsKeys, employment.id)) {
          return employment
        }
      })
    },

    showAllowanceSummary() {
      return !this.doesntHaveCalendarToRequestLeave
    },

    microcopyText() {
      if (this.draftedLeave) {
        const microcopyTexts = new LeaveRequestMicrocopy(
          this.draftedLeave.all,
          this.activeEmployment,
          this.selectedLeaveType,
          this.requestHasErrors
        )
          .microcopy()
          .split('**')

        let microcopyText = microcopyTexts[0]
        if (microcopyTexts.length === 3) {
          microcopyText +=
            ` <span class="tw-text-gray-700 tw-font-semibold">` +
            microcopyTexts[1] +
            `</span>` +
            microcopyTexts[2]
        }

        return microcopyText
      }

      return ''
    },

    requestHasErrors() {
      return (
        this.doesntHaveCalendarToRequestLeave ||
        this.hasRemainingAllowancesExceeded ||
        this.datesOverlapOnDifferentCalendars ||
        this.overlapWithExistingLeave
      )
    },

    exceedsRemainingEmploymentAllowanceText() {
      return new ExceedsRemainingEmploymentAllowanceText(
        this.activeEmployment,
        head(this.selectedEmployments)
      ).getText()
    },

    employmentAllowanceUnlimitedText() {
      return new EmploymentAllowanceUnlimitedText(
        this.activeEmployment,
        head(this.selectedEmployments)
      ).getText()
    },

    selectedLeaveTypeDoesntHaveAllowances() {
      if (!this.selectedLeaveType) {
        return false
      }

      return this.draftedLeave?.selectedLeaveTypeDoesntHaveAllowances
    },

    doesntHaveCalendarToRequestLeave() {
      return this.draftedLeave?.doesntHaveCalendarToRequestLeave
    },

    hasRemainingAllowancesExceeded() {
      return this.draftedLeave?.hasRemainingAllowancesExceeded
    },

    hasUnlimitedAllowances() {
      return this.draftedLeave?.hasUnlimitedAllowances
    },

    datesOverlapOnDifferentCalendars() {
      return this.draftedLeave?.datesOverlapOnDifferentCalendars
    },

    overlapWithExistingLeave() {
      return this.draftedLeave?.overlapWithExistingLeave
    },

    containPastDates() {
      return this.draftedLeave?.containPastDates
    },

    canRequestForMultipleEmployments() {
      return (
        this.activeEmployment.is_admin ||
        !!this.activeEmployment.subordinates.length
      )
    },

    selectedLeaveTypeHasAllowances() {
      return !!this.selectedLeaveType?.allowance_type_id
    },

    leaveSuccessMessage() {
      return this.approvalNeeded
        ? 'Your request has been submitted for approval.'
        : 'Your leave has been booked.'
    },

    approvalNeeded() {
      return (
        this.isSingleEmploymentLeaveRequest &&
        this.selectedLeaveType?.approval &&
        !this.creatorIsApprover
      )
    },

    availableEmployments() {
      return filter(this.employments, employment => {
        return (
          this.activeEmployment.is_admin ||
          employment.id === this.activeEmployment.id ||
          this.canApprove({ owner_id: employment.id }, this.activeEmployment)
        )
      }).map(employment => {
        return clone(employment)
      })
    },

    isSingleEmploymentLeaveRequest() {
      return this.selectedEmployments.length === 1
    },

    isMultipleEmploymentLeaveRequest() {
      return this.selectedEmployments.length > 1
    },

    activeTabIsEmployeeAllowances() {
      if (!this.activeTab) {
        return false
      }

      return this.activeTab.hash === '#employee-allowances'
    },

    creatorIsApprover() {
      let leaveOwner = this.draftedLeave.items[0].owner
      return some(leaveOwner.approvers, approver => {
        return approver.id === this.activeEmployment.id
      })
    },

    selectedEmployment: {
      get() {
        return head(this.selectedEmployments)
      },
      set(employment) {
        this.selectedEmployments = [employment]

        this.fetchEmployeesAllowancesAndDraftLeave()
      },
    },

    hasLeaveLimitExceeded() {
      return this.draftedLeave.containsLeaveLimitReachedWarning
    },

    selectedLeaveType() {
      return this.leaveTypes.find(
        leaveType => leaveType.id === this.form.type_id
      )
    },

    dateFormat() {
      return this.getFormatOfDayReadableDayNumberShortMonthYear()
    },

    hasHoursPerWorkingDayExceededWarning() {
      return this.draftedLeave.containsHoursPerWorkingDayExceededWarning
    },

    minutesPerWorkingDay() {
      if (this.isSingleEmploymentLeaveRequest) {
        return this.draftedLeave.all[0].owner.minutesPerWorkingDay
      }

      return this.activeCompany.minutes_per_working_day
    },

    selectableMinDate() {
      if (
        this.isSingleEmploymentLeaveRequest &&
        first(this.selectedEmployments).start_date
      ) {
        return first(this.selectedEmployments).start_date
      }

      if (!this.calendars.length()) {
        return null
      }

      const visibleCalendars = reject(this.calendars.all(), 'hidden')

      return minBy(visibleCalendars, 'start_date').start_date.format()
    },

    selectableMaxDate() {
      if (
        this.isSingleEmploymentLeaveRequest &&
        first(this.selectedEmployments).end_date
      ) {
        return first(this.selectedEmployments).end_date
      }

      if (!this.calendars.length()) {
        return null
      }

      const visibleCalendars = reject(this.calendars.all(), 'hidden')

      return maxBy(visibleCalendars, 'end_date').end_date.format()
    },
  },

  watch: {
    isSingleEmploymentLeaveRequest(newVal, oldVal) {
      if (newVal) {
        this.form.is_private = this.selectedEmployment.user
          ? this.selectedEmployment.user.keep_leave_private
          : false

        return
      }

      if (oldVal) {
        this.form.is_private = false
      }
    },
  },

  async created() {
    this.form.company_id = this.activeCompany.id

    await this.fetchCalendars()
    this.selectedEmployments = [this.employee]
    await this.fetchLeaveTypes()

    this.setDefaultLeaveType()

    await this.fetchEmployeesAllowancesAndDraftLeave()

    this.pageLoading = false
  },

  methods: {
    async handleDurationSelected(breakdown) {
      this.isDrafting = true
      this.selectedBreakdowns = this.selectedBreakdowns.map(
        originalBreakdown => {
          if (originalBreakdown.key === breakdown.key) {
            return breakdown
          }

          return originalBreakdown
        }
      )

      await this.redraftLeave()

      this.setLeaveAllowances()
      this.isDrafting = false
    },

    setLeaveAllowances() {
      this.leaveAllowances = new LeaveAllowances(
        this.draftedLeave.all,
        this.employmentAllowances
      )
    },

    setActiveTab(selectedTab) {
      this.activeTab = selectedTab.tab
    },

    changeTab(tabHash) {
      this.$refs.tabs.selectTab(tabHash)
    },

    openMultipleEmploymentPicker() {
      this.$refs.multipleEmploymentPicker.open()
    },

    updateAttachments(attachments) {
      this.attachments = attachments
    },

    setSelectedEmployments(selectedEmployments) {
      this.selectedEmployments = selectedEmployments
    },

    setDefaultLeaveType() {
      let leaveType =
        this.leaveTypes.find(leaveType => leaveType.is_default) ??
        head(this.leaveTypes)

      this.form.type_id = leaveType.id
    },

    async setLeaveType(leaveType) {
      this.form.type_id = leaveType.id

      await this.fetchEmployeesAllowancesAndDraftLeave()
    },

    setDateRange(period) {
      this.form.date_range = period.map(date => date.clone())

      this.fetchEmployeesAllowancesAndDraftLeave()
    },

    async requestLeave() {
      await this.validate()

      if (!this.valid || this.requestHasErrors) {
        return
      }

      if (this.hasLeaveLimitExceeded) {
        const confirmed = await this.confirm(
          'There is an issue with your request. Would you like to submit anyway?',
          'Warning'
        )

        if (!confirmed) return
      }

      if (this.hasHoursPerWorkingDayExceededWarning) {
        const confirmed = await this.confirm(
          'There is an issue with your request. Would you like to submit anyway?',
          'Warning'
        )

        if (!confirmed) return
      }

      this.submitting = true

      try {
        const { data } = await Leaves.request({
          ...omit(this.form, ['date_range']),
          from: this.draftedLeave.findMinimumFromTime(this.selectedBreakdowns),
          to: this.draftedLeave.findMaximumToTime(this.selectedBreakdowns),
          leave_breakdowns: this.draftedLeave.getBreakdownsToRequest(),
        })

        if (this.attachments.length) {
          await this.saveLeaveAttachments(data)
        }

        this.success(this.leaveSuccessMessage)

        this.$emit('hide')

        this.$emit('leave-requested')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.submitting = false
    },

    async saveLeaveAttachments(leaveIds) {
      for (const leaveId of leaveIds) {
        for (const attachment of this.attachments) {
          try {
            await Leaves.saveAttachment(leaveId, {
              company_id: this.activeCompany.id,
              attachment_id: attachment.id,
              file_name: attachment.name,
            })
          } catch ({ response }) {
            this.validateFromResponse(response)
          }
        }
      }
    },

    async fetchCalendars() {
      try {
        this.calendars = await Calendars.all(this.$route.query)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchLeaveTypes() {
      try {
        const { data } = await LeaveTypes.all(this.$route.query)

        this.leaveTypes = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async draftLeave() {
      try {
        this.draftedLeave = await Leaves.draft(
          {
            company: this.activeCompany,
            leaveType: this.selectedLeaveType,
            from: this.form.date_range[0],
            to: this.form.date_range[1],
          },
          this.selectedEmployments,
          this.employmentAllowances
        )

        this.selectedBreakdowns = [...this.draftedLeave.breakdowns]
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async redraftLeave() {
      try {
        this.draftedLeave = await Leaves.draft(
          {
            company: this.activeCompany,
            leaveType: this.selectedLeaveType,
            from: this.draftedLeave.findMinimumFromTime(
              this.selectedBreakdowns
            ),
            to: this.draftedLeave.findMaximumToTime(this.selectedBreakdowns),
          },
          this.selectedEmployments,
          this.employmentAllowances,
          this.draftedLeave.generateBreakdownsToRedraft(this.selectedBreakdowns)
        )

        this.selectedBreakdowns = [...this.draftedLeave.breakdowns]
      } catch (exception) {
        this.validateFromResponse(exception.response, false)
      }
    },

    async fetchEmploymentAllowances() {
      try {
        this.employmentAllowances = (
          await AllowanceReport.getDetailsAllowances({
            company_id: this.activeCompany.id,
            period: `${this.form.date_range[0].format(
              'YYYY-MM-DD'
            )},${this.form.date_range[1].format('YYYY-MM-DD')}`,
            allowance_type: this.selectedLeaveType.allowance_type_id,
            employee: this.selectedEmployments.length
              ? join(map(this.selectedEmployments, 'id'))
              : '-',
            per_page: this.selectedEmployments.length,
          })
        ).items()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchEmployeesAllowancesAndDraftLeave() {
      this.draftedLeave = null
      this.employmentAllowances = new EmploymentAllowanceCollection([])

      if (!this.selectedEmployments.length || !this.form.date_range.length) {
        return
      }

      if (!this.pageLoading) {
        this.dataLoading = true
      }

      if (this.selectedLeaveTypeHasAllowances) {
        await this.fetchEmploymentAllowances()
      }

      await this.draftLeave()

      if (this.employmentAllowances.length()) {
        this.setLeaveAllowances()
      }

      this.dataLoading = false
      this.pageLoading = false
    },
  },
}
</script>

<style scoped lang="scss">
@screen sm {
  .tab-column {
    max-height: calc(100vh - 224px);
  }
}
</style>
